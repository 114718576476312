import React from 'react';

const DealershipsList = ({dealerships, onItemClick}) => {

    return (
        <div className="cards-dealerships-container">
            {dealerships && dealerships.map(item => (
                <div className="card-body" onClick={(e) => onItemClick(e, item)} key={`dealership-card-${item.id}`}>
                    <h3>{item.name}</h3>
                    <p>
                        {item.address.split(',')[0]}<br></br>
                        <span className="phone-numbers">
                        {item.phone_numbers.split(',').map((number, i) => (
                            <a key={`item-${item.id}-phone-${i}`} href={"tel:" + number}>{number}</a>
                        ))}
                        </span>
                        <br></br>
                        <strong>{item.open_hours}</strong>
                    </p>
                    <div className="card-footer">
                        <i className="icon arrow-right"></i>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DealershipsList;