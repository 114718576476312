import React, { useEffect, useState } from 'react';
import { findDOMNode } from "react-dom";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import ModalDealershipDetails from './ModalDealershipDetails';
import DealershipsList from './DealershipsList';

const MapComponent = withScriptjs(withGoogleMap(({ selectedMarker, zoom, centerAt, markers, onItemClick, userLocation }) => {

    const styledMap = [{ "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }]

    const selectedIcon = {
        url: `${window._env_.STATIC_HOST}/static/website/images/map/selected-marker.svg`,
        size: new google.maps.Size(36, 63),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(18, 48),
    };

    const defaultIcon = {
        url: `${window._env_.STATIC_HOST}/static/website/images/map/default-marker.svg`,
        size: new google.maps.Size(12, 12),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(6, 6),
    };

    return (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={selectedMarker}
            center={centerAt.coords}
            zoom={zoom}
            options={{
                mapTypeControl: false,
                streetViewControl: false,
                minZoom: 4,
                maxZoom: 18,
                styles: styledMap,
                zoomControl: false,
                fullscreenControl: false
            }}
        >
            {markers && markers.map((marker) => {
                if (selectedMarker && selectedMarker.id === marker.id) return;
                return <Marker
                    key={`${marker.id}`}
                    position={{ lat: Number(marker.coords.lat), lng: Number(marker.coords.lng) }}
                    icon={defaultIcon}
                    onClick={(e) => onItemClick(e, marker)}
                />
            })}
            {selectedMarker &&
                <Marker
                    key={`${selectedMarker.id}`}
                    position={selectedMarker.coords}
                    icon={selectedIcon}
                />
            }
            {userLocation &&
                <Marker
                    key={`userLocation`}
                    position={userLocation.coords}
                    zIndex={0}
                />
            }

        </GoogleMap>
    )
}));

const MapContent = ({ mapState, markers, onItemClick, clearSelectedMarker }) => {

    const [viewportWidth, setViewportWidth] = useState(0);
    const breakpointMobile = 992;

    useEffect(() => {
        const _width = findDOMNode(document.body);
        setViewportWidth(_width.clientWidth);
    }, []);

    return (
        <div className="map-container">
            <MapComponent
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_V3_APIKEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div className="map-inside" />}
                containerElement={<div className="map-inside" />}
                mapElement={<div className="map-inside" />}
                selectedMarker={mapState.selectedMarker}
                userLocation={mapState.userLocation}
                zoom={mapState.mapConfig.zoom}
                centerAt={mapState.centerAt}
                markers={markers}
                onItemClick={onItemClick}
            />
            <div className="main-container">
                {mapState.selectedMarker ?
                    <ModalDealershipDetails selectedMarker={mapState.selectedMarker} clearSelectedMarker={clearSelectedMarker} />
                    :
                    <>
                        {viewportWidth > breakpointMobile ?
                            <DealershipsList dealerships={markers} onItemClick={onItemClick} />
                            : null}
                    </>
                }
            </div>
        </div>
    );
}

export default MapContent;