import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';

import MapContent from '../components/MapContent';

const initialMapState = {
    isLoading: false,
    centerAt: { coords: { lat: -33.4488897, lng: -70.6692655 } },
    selectedMarker: null,
    userLocation: null,
    showResults: false,
    mapConfig: {
        zoom: 12
    },
}

const DealersMap = () => {

    const [mapState, setMapState] = useState(initialMapState);
    const [dealerships, setDealerships] = useState([]);
    const [search, setSearch] = useState('');
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([])
    const [toggleFilter, setToggleFilter] = useState(false);
    const [toggleSearchBox, setToggleSearchBox] = useState(false);

    const containerRefFilter = useRef(null);
    const openRefFilter = useRef(null);

    const containerRefSearchBox = useRef(null);
    const openRefSearchBox = useRef(null);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", (e) => handleClickOutside(e, openRefFilter, containerRefFilter, setToggleFilter));
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", (e) => handleClickOutside(e, openRefFilter, containerRefFilter, setToggleFilter));
        };
    }, [toggleFilter]);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", (e) => handleClickOutside(e, openRefSearchBox, containerRefSearchBox, setToggleSearchBox));
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", (e) => handleClickOutside(e, openRefSearchBox, containerRefSearchBox, setToggleSearchBox));
        };
    }, [toggleSearchBox]);

    useEffect(() => {
        Axios.get(`/api/dealerships/dealerships`)
            .then(response => {
                initData(response.data);
            })
            .catch((error) => {
                console.error(error);
            });

        Axios.get(`/api/dealerships/services`)
            .then(response => {
                initServices(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleClickOutside = (event, ref, containerRef, callback) => {
        if (ref.current && ref.current.contains(event.target)) {
            callback(true);
        }
        else if (containerRef.current && !containerRef.current.contains(event.target)) {
            callback(false);
        }
    }

    const initServices = data => {
        const services = data.map(elem => ({
            value: elem.name,
            isChecked: false
        }))
        setServices(services);
    }

    const initData = data => {
        setDealerships(data);
    }

    const handleCheck = index => {
        let servicesCopy = [...services];
        servicesCopy[index].isChecked = !servicesCopy[index].isChecked;
        setServices(servicesCopy);
        servicesCopy = servicesCopy.filter(elem => elem.isChecked).map(elem => elem.value);
        setSelectedServices(servicesCopy);
    }

    const onItemClick = (e, item) => {
        item.coords = { lat: Number(item.coords.lat), lng: Number(item.coords.lng) };
        setToggleSearchBox(false);
        setMapState(prevState => ({
            ...prevState,
            selectedMarker: item,
            centerAt: item,
            mapConfig: {
                zoom: 16
            }
        }));
    };

    const clearSelectedMarker = () => {
        setMapState(prevState => ({
            ...prevState,
            selectedMarker: null
        }));
    }

    const getGeoLocationData = () => {
        if (navigator && navigator.geolocation) {
            // Geolocation can take a couple seconds on WiFi
            //   setState({ isLoading: true });

            navigator.geolocation.getCurrentPosition((position) => {
                const userLocation = {
                    id: 'userMarker',
                    latitud: position.coords.latitude,
                    longitud: position.coords.longitude,
                    coords: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                    nombre: 'Tu ubicación',
                    numero: '',
                };

                const zoomLevel = (window.innerWidth <= 768) ? 15 : 20;

                setMapState(prevState => ({
                    centerAt: userLocation,
                    selectedMarker: null,
                    userLocation: userLocation,
                    mapConfig: { ...prevState.mapConfig, zoom: zoomLevel },
                }));

            }, (error) => {
                // Blocked
                alert("Tienes bloqueada la geolocalización en tu navegador. Actívala nuevamente o usa el buscador.")
                console.log('location error', error);
            });
        } else {
            // Browser doesn't support Geolocation
            alert("Tu navegador no soporta geolocalización. Prueba usar el buscador.")
            console.log('Browser does not support Geolocation');
        }
    };

    const visibleDealerships = dealerships.filter(dealer => {
        let servicesMatch, searchMatch;

        if (selectedServices.length > 0) {
            servicesMatch = false;
            selectedServices.forEach(selServ => {
                if (dealer.services.includes(selServ)) {
                    servicesMatch = true;
                }
            });
        } else {
            servicesMatch = true;
        }

        if (search.length >= 3) {
            if (
                dealer.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                dealer.address.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                dealer.region.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                dealer.city.toLowerCase().indexOf(search.toLowerCase()) >= 0
            ) {
                searchMatch = true;
            } else {
                searchMatch = false;
            }
        } else {
            searchMatch = true;
        }

        return servicesMatch && searchMatch;
    })

    return (
        <div className="map-dealerships-container">
            <div className="map-filters main-container">
                <h2>Encuentra tu concesionario Toyota</h2>
                <div className="filter-options">
                    <span className="current-location" onClick={getGeoLocationData}><i className="icon location"></i>Usar mi ubicación actual</span>
                    <div className="select-list-filter">
                        <div className={"select-title " + (toggleFilter ? 'active' : '')} ref={openRefFilter} onClick={() => setToggleFilter(true)}>Filtrar por <i className="icon chevron-down"></i></div>
                        {toggleFilter &&
                            <ul ref={containerRefFilter}>
                                <li>
                                    {services && services.map((item, i) => (
                                        <div key={`service-${i}`}>
                                            <input onChange={() => handleCheck(i)} type="checkbox" checked={item.isChecked} /><span>{item.value}</span>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="search-box">
                        <input type="text" name="search" className="input-search" placeholder="Concesionaria o Ciudad" ref={openRefSearchBox} onClick={() => setToggleSearchBox(true)} onChange={(e) => setSearch(e.target.value)}></input>
                        {toggleSearchBox &&
                            <ul ref={containerRefSearchBox}>
                                {visibleDealerships && visibleDealerships.map(dealer => (
                                    <li key={`searched-dealer-${dealer.id}`} onClick={(e) => onItemClick(e, dealer)}>
                                        <b>{dealer.name}</b>
                                        <span>{dealer.address.split(',')[0]}</span>
                                    </li>
                                ))}
                                {!visibleDealerships.length ?
                                    <li>
                                        <p>No se encontraron resultados</p>
                                    </li>
                                    : null}
                            </ul>
                        }
                    </div>
                </div>
            </div>
            <MapContent mapState={mapState} markers={visibleDealerships} onItemClick={onItemClick} clearSelectedMarker={clearSelectedMarker} />
        </div>
    );
}

export default DealersMap;
