import React, { useEffect } from 'react';
import { findDOMNode } from "react-dom";

const ModalDealershipDetails = ({selectedMarker, clearSelectedMarker}) => {

    const breakpointMobile = 992;

    useEffect(() => {
        const _width = findDOMNode(document.body);
        if (_width.clientWidth <= breakpointMobile) {
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = null;
            }
        }
    }, []);

    return (
        <div className="modal-dealership-details">
            <div className="bg-modal"></div>
            <div className="modal-box">
                <div className="modal-header">
                    <div className="modal-close" onClick={clearSelectedMarker}>✕</div>
                    <i className="icon www"></i>
                    <h3>{selectedMarker.name}</h3>
                    <div className="close-modal"></div>
                </div>
                <div className="modal-body">
                    <p>{selectedMarker.open_hours}</p>
                    <hr></hr>
                    <div className="subtitle">Dirección</div>
                    <p>
                        {selectedMarker.address}
                    </p>
                    <div className="map-options">
                        <a href={`https://www.google.com/maps/search/?api=1&query=${selectedMarker.coords.lat},${selectedMarker.coords.lng}`} target="_blank" className="btn block outline-black with-icon"><i className="icon g-maps"></i>Ver en Google Maps</a>
                        <a href={`https://www.waze.com/ul?ll=${selectedMarker.coords.lat}%2C${selectedMarker.coords.lng}&navigate=yes&zoom=17`} target="_blank" className="btn block outline-black with-icon"><i className="icon waze"></i>Ver en Waze</a>
                    </div>
                    <hr></hr>
                    <div className="subtitle">Teléfonos</div>
                    <p>
                        {selectedMarker.phone_numbers.split(',').map((number, i) => (
                            <a key={`phone-${i}`} href={"tel:" + number}>{number}</a>
                        ))}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ModalDealershipDetails;